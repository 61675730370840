<template>
    <div class="product-container">
        <a-card title="产品服务管理">
            <div class="tool">
                <a-input-search style="width: 250px;" v-model="keyword" placeholder="请输入项目名称" allowClear enter-button @search="onSearch" />
                <div>
                    <a-range-picker :locale="locale" @change="pickerChange" style="margin-right: 10px"/>
                    <a-button type="primary" icon @click="add"><a-icon type="plus"></a-icon>新增</a-button>
                </div>
            </div>
            <div class="table">
                <a-table :rowKey="(item,i)=>item.id" :columns="columns" :data-source="tableData" :pagination="pagination" :loading="loading"></a-table>
            </div>
        </a-card>
        <a-modal :width="900" v-model="editWindowShow" :title="modalTitle" @ok="modalOk" cancelText="取消" okText="确定" :confirmLoading="confirmLoading">
            <a-form-model ref="form" :rules="formRules" :model="formData" :label-col="{span:5}" :wrapper-col="{span:12}">
                <a-form-model-item label="产品名称：" prop="name">
                    <a-input v-model="formData.name" placeholder="请输入产品名称"></a-input>
                </a-form-model-item>
                <a-form-model-item label="首页展示：">
                    <a-switch v-model="formData.homeShow" />
                </a-form-model-item>
                <a-form-model-item label="展示图：">
                    <upload ref="uploadCover" action="" :imgList="formData.coverImg" upload-length="1" @uploadChange="handleChangeCover"></upload>
                </a-form-model-item>
                <a-form-model-item  label="产品介绍：" prop="introduce">
                    <br/>
                    <div>
                        <div v-for="(domain, index) in dynamicValidateForm.domains" :key="index">
                            <div>
                                <span>产品特点：</span>
                            </div>
                            <a-input  v-model="domain.characteristic" placeholder="请输入产品特点" style="width: 150px; margin-right: 8px"/>
                            <div>
                                <span>产品介绍：</span>
                            </div>
                            <a-textarea v-model="domain.introduce" placeholder="请输入产品介绍" :auto-size="{ minRows: 3, maxRows: 5 }"/>
                            <a-icon
                                v-if="dynamicValidateForm.domains.length > 1"
                                class="dynamic-delete-button"
                                type="minus-circle-o"
                                :disabled="dynamicValidateForm.domains.length === 1"
                                @click="removeDomain(domain)"
                            />
                        </div>
                        <a-button type="dashed" style="width: 120px" @click="addDomain"><a-icon type="plus" />添加</a-button>
                    </div>
                    <br/>
                </a-form-model-item>

                <a-form-model-item  label="功能介绍：" prop="scenario">
                    <br/>
                    <div>
                        <div class="applyScene" v-for="(domain, index) in dynamicValidateFormApplyScene.domains" :key="index">
                            <div style="display: flex;align-items: center">
                                <a-icon
                                    v-if="dynamicValidateFormApplyScene.domains.length > 1"
                                    class="dynamic-delete-button"
                                    type="minus-circle-o"
                                    :disabled="dynamicValidateFormApplyScene.domains.length === 1"
                                    @click="removeDomainApplyScene(domain)"
                                />
                                <span>功能名称：</span>
                            </div>
                            <a-input v-model="domain.name" placeholder="请输入场景名称" style="width: 150px; margin-right: 8px"/>
                            <div>
                                <span>功能截图：</span>
                            </div>
                            <upload :ref="'upload'+index" upload-length="6" @uploadChange="handleChange(index,$event)"></upload>
                            <div class="editor" v-if="editWindowShow">
                                <Toolbar style="border-bottom: 1px solid #ccc;" :editor="editorRef[index]" :defaultConfig="editorConfig.toolbarConfig" :mode="mode" />
                                <Editor v-model="domain.func" :defaultConfig="editorConfig" :mode="mode" @onCreated="handleCreated" />
                            </div>
                        </div>
                        <a-button type="dashed" style="width: 120px" @click="addDomainApplyScene"><a-icon type="plus" />添加</a-button>
                    </div>
                    <br/>
                </a-form-model-item>
            </a-form-model>
        </a-modal>
    </div>
</template>

<script>
import locale from 'ant-design-vue/es/date-picker/locale/zh_CN';
import {h} from "vue";
import { toolbarConfig, MENU_CONF } from '@/utils/wangeditors.js'
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import upload from "@/components/upload.vue";
import {saveOrEditProduct,selectProduct,deleteProduct} from "@/api/apis";
import handleRequest from "@/utils/handleRequest";
export default {
    name: "productOption",
    components: {
        Editor, Toolbar,upload
    },
    data() {
        return {
            mode:'default',
            editorRef: [],
            editorConfig: {
                placeholder: '请输入内容...',
                //配置工具栏
                toolbarConfig: toolbarConfig,
                MENU_CONF: MENU_CONF,
            },
            keyword:'',
            locale,
            editWindowShow:false,
            modalTitle:'',
            action:'',
            formData:{
                sort:'',
                name:'',
                func:'',
                state:2,
                introduce:[{}],
                scenario:[{}],
                homeShow:false,
                coverImg:''
            },
            loading:false,
            confirmLoading:false,
            startTime:'',
            endTime:'',
            pagination:{
                pageSize:10,
                current:1,
                total:0,
                showTotal:(total)=>`共${total}条`,
                onChange:(page,pageSize)=>this.pageChange(page,pageSize)
            },
            dynamicValidateForm: {
                domains: [],
            },
            dynamicValidateFormApplyScene: {
                domains: [],
            },
            formRules: {
                name: [{required:true,message:'请输入名称',trigger:'blur'}],
                func: [{required:true,message:'请输入内容',trigger:'blur'}],
                introduce:[{validator:(rules,value,callback)=>{
                    if(this.formData.introduce.length){
                        if(this.formData.introduce[0].characteristic){
                            return true
                        }else{
                            callback('请填写产品特点和介绍')
                        }
                    }else{
                        callback('请添加产品介绍')
                    }
                    },trigger:'blur'}],
                scenario:[{validator:(rules,value,callback)=>{
                        if(this.formData.scenario.length){
                            if(this.formData.scenario[0].name && this.formData.scenario[0].img){
                                return true
                            }else{
                                callback('请填写场景名称并且上传至少一张图片')
                            }
                        }else{
                            callback('请添加应用场景')
                        }
                    },trigger:'blur'}]
            },
            columns:[
                {
                    title: '序号',
                    dataIndex: 'index',
                    key: 'index',
                    align: 'center',
                    customRender: (text,record,index) => `${index+1}`,
                },
                {
                    align: 'center',
                    dataIndex:'name',
                    key:'name',
                    title:'产品名称',
                },
                {
                    align: 'center',
                    dataIndex:'createTime',
                    key:'createTime',
                    title:'添加时间',
                },
                {
                    align: 'center',
                    dataIndex:'status',
                    key:'status',
                    title:'状态',
                    customRender:(text, record, index)=> {
                        return  h("a-switch",{
                            props:{
                                checked:record.state == 1 ? true:false
                            },
                            on:{
                                'change':()=>{
                                    this.switchChange(record)
                                }
                            }
                        })
                    }
                },
                {
                    align: 'center',
                    dataIndex:'action',
                    key:'action',
                    title:'操作',
                    width:200,
                    customRender:(text,record,index)=>{
                        return h("div",[
                            h("a-button", {
                                style:{
                                    marginRight:'10px'
                                },
                                props:{
                                    icon:'edit',
                                    type:'primary',
                                    size:'small'
                                },
                                on:{
                                    click:()=>{
                                        this.edit(record,index)
                                    }
                                }
                            }, '编辑'),
                            h("a-popconfirm",{
                                props:{
                                    title:'确定要删除吗？',
                                    okText:'确定',
                                    cancelText:'取消',
                                },
                                on:{
                                    'confirm':()=>{
                                        this.delete(record)
                                    }
                                }
                            },[
                                h("a-button",{
                                    props:{
                                        icon:'delete',
                                        type: 'danger',
                                        size:'small',
                                    }
                                },'删除')
                            ])
                        ])
                    }
                },
            ],
            tableData:[
                {
                    id:'1',
                    sort:'1',
                    name:'项目1方法',
                    func:'富文本内容',
                    state:2,
                    homeShow:false,
                    introduce:[
                        {
                            characteristic:'服务器大',
                            introduce:'这个项目非常的牛逼'
                        },
                    ],
                    scenario:[
                            {
                                name:'场景1',
                                img:[
                                    {
                                        name:'图片1',
                                        uid:'1',
                                        status: 'done',
                                        url:"https://uploadfile.bizhizu.cn/up/46/96/1e/46961ec99c6f51f9663d72df41859aa6.jpg.source.jpg"
                                    }
                                ]
                            },
                            {
                                name:'场景2',
                                img:[
                                    {
                                        name:'图片2-1',
                                        uid:'13',
                                        status: 'done',
                                        url:"https://uploadfile.bizhizu.cn/up/46/96/1e/46961ec99c6f51f9663d72df41859aa6.jpg.source.jpg"
                                    },
                                    {
                                        name:'图片2-2',
                                        uid:'14',
                                        status: 'done',
                                        url:"https://uploadfile.bizhizu.cn/up/46/96/1e/46961ec99c6f51f9663d72df41859aa6.jpg.source.jpg"
                                    }
                                ]
                            },
                            {
                                name:'场景3',
                                img:[
                                    {
                                        name:'图片22222222',
                                        uid:'1asd23',
                                        status: 'done',
                                        url:"https://uploadfile.bizhizu.cn/up/46/96/1e/46961ec99c6f51f9663d72df41859aa6.jpg.source.jpg"
                                    },
                                    {
                                        name:'图片22222222',
                                        uid:'1asd23f',
                                        status: 'done',
                                        url:"https://uploadfile.bizhizu.cn/up/46/96/1e/46961ec99c6f51f9663d72df41859aa6.jpg.source.jpg"
                                    }
                                ]
                            },
                    ],
                },
            ],
            temp:[],
        }
    },
    watch: {
        editWindowShow(newValue, oldValue) {
            if(!newValue){
                this.editorRef = []
            }
        }
    },
    mounted() {
        this.getTableData();
    },
    methods: {
        pageChange(page,pageSize){
            this.pagination.current = page
            this.getTableData()
        },
        async getTableData(){
            this.loading = true
            let params = {
                name:this.keyword,
                page:this.pagination.current,
                pageSize: this.pagination.pageSize,
                startTime:this.startTime || '',
                endTime:this.endTime || '',
            }
            const res = await selectProduct(params)
            const data = handleRequest(res)
            data.content.forEach((item,i)=>{
                if(item.scenario && item.introduce){
                    item.introduce = JSON.parse(item.introduce)
                    item.scenario = JSON.parse(item.scenario)
                }
            })
            this.tableData = data.content
            this.loading = false
        },
        handleChangeCover(list){
            this.formData.coverImg = list
            this.formData.coverImg = JSON.stringify(this.formData.coverImg)
        },
        handleChange(index,list){
            this.dynamicValidateFormApplyScene.domains[index].img = list
        },
        onSearch(){
            this.getTableData()
        },
        pickerChange(date,dateString){
            this.startTime = dateString[0]
            this.endTime = dateString[1]
            this.pagination.current = 1
            this.getTableData()
        },
        async switchChange(params){
            console.log(params)
            params.state = params.state == 1 ? false:true
            let switchedData = {...params}
            switchedData.introduce = JSON.stringify(switchedData.introduce)
            switchedData.scenario = JSON.stringify(switchedData.scenario)
            switchedData.state = switchedData.state == true ? 1:2
            const res = await saveOrEditProduct(switchedData)
            handleRequest(res,(res)=>{
                this.$message.success('切换成功')
            })
        },
        handleCreated(editor) {
            this.editorRef.push(editor)//由于多个富文本编辑器 需要组成array
        },
        addDomain() {
            if(this.dynamicValidateForm.domains.length>=3){
                this.$message.error('至多3条');
                return
            }else{
                this.dynamicValidateForm.domains.push({
                    characteristic: '',
                    // key: Date.now(),
                });
            }
        },
        addDomainApplyScene() {
            if(this.dynamicValidateFormApplyScene.domains.length>=6){
                this.$message.error('至多6条');
                return
            }else{
                this.dynamicValidateFormApplyScene.domains.push({
                    name: '',
                    // key: Date.now(),
                });
            }
        },
        removeDomainApplyScene(item) {
            let index = this.dynamicValidateFormApplyScene.domains.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateFormApplyScene.domains.splice(index, 1);
            }
        },
        removeDomain(item) {
            let index = this.dynamicValidateFormApplyScene.domains.indexOf(item);
            if (index !== -1) {
                this.dynamicValidateFormApplyScene.domains.splice(index, 1);
            }
        },
        modalOk(){
            this.confirmLoading = true
            console.log('编辑窗口确定')
            this.formData.introduce = this.dynamicValidateForm.domains
            this.formData.scenario = this.dynamicValidateFormApplyScene.domains
            this.$refs.form.validate(async valid => {
                if (valid) {
                    this.formData.introduce = JSON.stringify(this.formData.introduce)
                    this.formData.scenario = JSON.stringify(this.formData.scenario)
                    this.formData.coverImg = JSON.stringify(this.formData.coverImg)

                    const res = await saveOrEditProduct(this.formData)
                    handleRequest(res,(res)=>{
                        if(res.code == 200){
                            this.$message.success('操作成功')
                            this.confirmLoading = false
                            this.editWindowShow = false
                            this.getTableData()
                        }
                    })
                } else {
                    console.log('验证错误');
                    console.log(this.formData)
                    this.confirmLoading = false
                    return false;
                }
            });
        },
        add(){
            this.modalTitle = '新增'
            this.$nextTick(()=>{
                this.editWindowShow = true
                this.formData = {
                    sort:'',
                    name:'',
                    func:'',
                    state:2,
                    introduce:[{
                        characteristic:'',
                        introduce:''
                    }],
                    scenario:[{}],
                    homeShow:false
                }
                this.dynamicValidateForm.domains = [{
                    characteristic:'',
                    introduce:''
                }]
                this.dynamicValidateFormApplyScene.domains = []
            })

        },
        edit(params,index){
            this.editWindowShow = true
            this.modalTitle = '编辑'
            this.formData = params
            console.log('当前编辑的表单数据：',this.formData,index)

            if(typeof this.formData.introduce == "string" && typeof this.formData.scenario == "string"){
                this.formData.introduce = JSON.parse(this.formData.introduce)
                this.formData.scenario = JSON.parse(this.formData.scenario)
            }
            if(typeof this.formData.coverImg == "string"){
                this.formData.coverImg = JSON.parse(this.formData.coverImg)
            }
            this.dynamicValidateForm.domains = this.formData.introduce
            this.dynamicValidateFormApplyScene.domains = this.formData.scenario
            //嵌套了多个上传组件需要下标进行寻找并赋值
            this.$nextTick(()=>{
                this.dynamicValidateFormApplyScene.domains.forEach((item,i)=>{
                    this.$refs['upload'+i][0].imgList = item.img
                })
                this.$refs.uploadCover.imgList = this.formData.coverImg
            })
        },
        async delete(params){
            console.log(params)
            const res = await deleteProduct({id:params.id})
            handleRequest(res,res=>{
                if(res.code == 200){
                    this.$message.success('删除成功')
                    this.getTableData()
                }
            })
        }
    },
}
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style scoped lang="scss">
::v-deep .w-e-scroll{
    min-height: 300px;
}
.editor{
    z-index: 999999;
    width: 600px;
    min-height: 300px;
    border: 1px solid #8d8d8d;
}
.applyScene{
    width:630px;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #8d8d8d;
}
.dynamic-delete-button{
    padding: 0 10px;
}
.product-container{
    padding: 20px;
    .tool{
        display: flex !important;
        justify-content: space-between;
        align-items: center;
    }
    .table{
        margin-top: 30px;
    }
}
</style>
